// Imports
@import "variables";

@media (max-width: $bp-medium) {

  #refinar-busca {
    .refinar-busca-menu {
      .price p,
      .produtos p,
      .sabores p {
        font-size: 21px;
      }

      .price ul li a,
      .produtos ul li a,
      .sabores ul li a {
        font-size: 16px;
      }
    }
  }
  .product-list {
    width: 640px;
    margin: 0 auto;
    text-align: center;
  }
  .product-list li {
    height: auto;
    width: 100%;
    margin: 2px 0 15px !important;
    list-style: none;
    background: #f5f3f0;
    display: inline-block;
    vertical-align: top;
  }
  .product-list li:first-child + li {
    margin: 0 3px;
  }
  .product-list li .heart {
    float: right;
    margin: 15px;
    font-size: 22px;
    color: #898989;
    text-decoration: none;
  }
  .product-list li .heart.selected {
    color: #d7ad5f;
  }
  .product-list li .link {
    text-align: center;
    display: block;
    text-decoration: none;
  }
  .product-list li .link h3 {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
    width: 255px;
    margin: auto;
    padding: 40px 20px 0 20px;
  }
  .product-list li .link p {
    margin: auto;
    width: 255px;
    margin-top: -2px;
    padding: 0px 20px 5px 20px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 14px;
    color: #898989;
  }
  .product-list li .link span {
    margin: auto;
    width: 255px;
    display: block;
    padding: 5px 20px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 25px;
    color: #4f2c1e;
  }
  .product-list li .link span small {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 20px;
    color: #4f2c1e;
    margin-right: 4px;
  }
  .product-list.mais-vendidos li .sabores {
    width: 334px;
    height: 40px;
    font-size: 20px;
    margin-left: -167px;
    left: 50%;
    text-transform: uppercase;
  }
  .product-list.mais-vendidos li .sabores:before {
    content: '';
    width: 104px;
    height: 1px;
    margin-left: -52px;
    background-color: #775ca7;
    position: absolute;
    top: -1px;
    left: 50%;
  }
  #best-seller-carousel-mobile {
    padding-bottom: 75px;
  }
  #identificacao {
    background: #fff;
    height: auto;
    padding-bottom: 30px;
  }
  #identificacao .center-identification {
    width: 620px;
  }
  #identificacao .center-identification .left-indentification,
  #identificacao .center-identification .middle-indentification,
  #identificacao .center-identification .right-indentification {
    padding: 0 0 30px 0;
    margin: 0;
    background: #f1e8e0;
  }
  #identificacao .center-identification .left-indentification .title,
  #identificacao .center-identification .middle-indentification .title,
  #identificacao .center-identification .right-indentification .title {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 35px;
    color: $brown;
    padding: 35px;
    margin-bottom: 0;
  }
  #identificacao .center-identification .left-indentification {
    padding-bottom: 30px;
    margin-top: 30px;
  }
  #identificacao .center-identification .left-indentification .form-group {
    text-align: center;
    padding: 0 40px;
  }
  #identificacao .center-identification .left-indentification .form-group div {
    margin: 20px 0;
  }
  #identificacao .center-identification .left-indentification .form-group div label {
    display: block;
    text-align: center;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 20px;
    color: #85685b;
  }
  #identificacao .center-identification .left-indentification .form-group div input {
    height: 60px;
    border: none;
  }
  #identificacao .center-identification .left-indentification .form-group button {
    float: none;
    text-align: center;
    width: 340px;
  }
  #identificacao .center-identification .left-indentification a {
    margin: 50px 0px 0 0;
    text-align: center;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 20px;
    color: #775ca7;
    text-decoration: none;
  }
  #identificacao .center-identification .middle-indentification {
    height: auto;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    margin-top: 10px;
  }
  #identificacao .center-identification .middle-indentification .text {
    text-align: center;
    margin: 0 25px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 25px;
    color: #85685b;
  }
  #identificacao .center-identification .middle-indentification a {
    display: block;
    text-align: center;
    margin: 20px 30px 0 30px;
  }
  #identificacao .center-identification .right-indentification {
    margin-top: 10px;
  }
  #identificacao .center-identification .right-indentification .text {
    text-align: center;
    margin: 0 25px 20px 25px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 25px;
    color: #85685b;
  }
  #identificacao .center-identification .right-indentification .fb button {
    width: 295px;
    height: 61px;
    background: url("../img/facebook-entrar-mobile.png");
  }
  #identificacao .center-identification .right-indentification .ob a {
    width: 295px;
    height: 61px;
    background: url("../img/entrar-onebuy-mobile.png");
  }
  #loja-online {
    background: #fff;
  }
  #loja-online h2 {
    padding: 30px 0;
    text-align: center;
    margin: auto;
  }
  #loja-online ul {
    padding: 0 10px 0 10px;
  }
  #loja-online ul li {
    list-style: none;
    margin: 20px 0;
  }
  #loja-online ul li .panel-heading {
    padding: 0 !important;
    text-align: center;
    border: 0 !important;
  }
  #loja-online ul li .panel-heading a {
    background: #c8b59e;
    padding: 20px 0;
    display: block;
    text-align: center;
    text-decoration: none;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 47px;
    color: #4f2c1e;
  }
  #loja-online ul li .panel-heading a.collapsed {
    background: #e9e0d7;
  }
  #loja-online ul li .well {
    padding: 50px 20px;
    background: #e9e0d7;
    margin-bottom: 0 !important;
  }
  #loja-online ul li #como-comprar-mobile {
    text-align: center;
  }
  #loja-online ul li #como-comprar-mobile p {
    line-height: 32px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
  }
  #loja-online ul li #como-comprar-mobile .sub-title {
    text-transform: uppercase;
    font-family: 'martelheavy';
    margin-bottom: 5px;
  }
  #loja-online ul li #como-comprar-mobile span {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
    line-height: 32px;
    font-style: italic;
  }
  #loja-online ul li #entrega-mobile-2 {
    text-align: center;
  }
  #loja-online ul li #entrega-mobile-2 p {
    line-height: 32px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
  }
  #loja-online ul li #entrega-mobile-2 p > a {
    text-decoration: underline !important;
    background: transparent;
    padding: 0 !important;
    display: block !important;
    text-align: normal !important;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px !important;
    color: #513593 !important;
    text-decoration: none;
  }
  #loja-online ul li #entrega-mobile-2 .title {
    font-family: 'martelheavy';
    margin-bottom: 5px;
  }
  #loja-online ul li #termo-de-uso-mobile {
    text-align: center;
  }
  #loja-online ul li #termo-de-uso-mobile p {
    line-height: 32px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
  }
  #cada-trufa+div {
    padding-bottom: 70px;
  }
  #truffes .mobile .carousel ul.item {
    padding: 0;
    margin: 0;
  }
  #truffes .mobile .carousel ul.item li {
    width: 50%;
    height: 200px;
    list-style: none;
    text-align: center;
    float: left;
  }
  #truffes .mobile .carousel ul.item li a {
    text-decoration: none;
  }
  #truffes .mobile .carousel ul.item li a .img {
    height: 150px;
  }
  #truffes .mobile .carousel ul.item li a p {
    font-size: 20px;
    margin: 15px 0 0 0;
  }
  #truffes .mobile.title {
    margin-top: 50px;
  }
  #truffes .mobile.title h2 {
    color: #af538a;
  }
  #truffes .mobile.title p {
    color: #887661;
  }
  #modal-truffas .carousel {
    background: #fff;
  }
  #modal-truffas .carousel ul {
    padding: 0;
  }
  #modal-truffas .carousel ul li {
    list-style: none;
  }
  #modal-truffas .carousel ul li .title {
    margin: 20px auto;
  }
  #modal-truffas .carousel ul li .title p {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 34px;
    color: #3c0f74;
  }
  #modal-truffas .carousel ul li .img-modal {
    text-align: center;
    width: 224px;
    margin: auto;
  }
  #modal-truffas .carousel ul li .img-modal img {
    width: 100%;
    height: auto;
  }
  #modal-truffas .carousel ul li .description-modal {
    padding: 20px 30px;
    text-trasnform: none;
  }
  #modal-truffas .carousel ul li .description-modal p {
    text-transform: none !important;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #3c0f74;
  }
  #modal-truffas .carousel .arrows {
    width: 200px;
    margin: auto;
    text-align: center;
  }
  #modal-truffas .carousel .arrows div {
    display: inline-block;
    vertical-align: top;
  }
  #modal-truffas .carousel .arrows .number p {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    font-family: 'martellight';
    font-weight: normal;
    font-size: 26px;
    color: #c8b59e;
  }
  #modal-truffas .carousel .arrows .number .active {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 35px;
    color: #775ca7;
    line-height: 45px;
  }
  #modal-truffas .carousel .arrows .arrow-left .left {
    background: #fff !important;
    top: auto;
    left: -80px !important;
    margin-left: 50%;
    bottom: 70px;
  }
  #modal-truffas .carousel .arrows .arrow-left .left:after {
    border-color: transparent #fff transparent #000;
  }
  #modal-truffas .carousel .arrows .arrow-left .left:before {
    border-color: transparent #000 transparent #fff;
  }
  #modal-truffas .carousel .arrows .arrow-right .right {
    background: #fff !important;
    top: auto;
    right: -80px !important;
    margin-right: 50%;
    bottom: 70px;
  }
  #modal-truffas .carousel .arrows .arrow-right .right:after {
    border-color: transparent #000 transparent #fff;
  }
  #modal-truffas .carousel .arrows .arrow-right .right:before {
    border-color: transparent #fff transparent #000;
  }
  #blocos {
    max-height: 100%;
    margin-bottom: 58px;
  }
  #blocos .left-blocos {
    width: 478px;
    height: auto;
    max-height: 100%;
    padding: 30px;
    margin: auto;
    text-align: center;
    overflow: hidden;
  }
  #blocos .left-blocos.purple-left {
    background: url("../img/purple-bg-mobile.jpg") center;
  }
  #blocos .left-blocos h3 {
    width: 100%;
    font-family: 'martellight';
    font-size: 35px;
    margin-bottom: 40px;
  }
  #blocos .left-blocos p {
    font-size: 26px;
    line-height: 29px;
  }
  #blocos .left-blocos ul li {
    font-size: 20px;
  }
  #blocos .left-blocos ul.list {
    margin: 50px 0 0 0;
  }
  #blocos .left-blocos ul.list:before {
    width: 3px;
    height: 134px;
    background: #be9c6b;
    top: 15px;
  }
  #blocos .left-blocos ul.list li {
    padding: 15px;
    text-align: left;
    color: #be9c6b;
  }
  #blocos .left-blocos.cinza {
    height: auto !important;
    background-repeat: round;
  }
  #blocos .left-blocos.cinza a {
    width: auto;
  }
  #blocos .left-blocos.dark-brown {
    height: auto !important;
  }
  #blocos .right-blocos {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
    z-index: 2;
  }
  #blocos .right-blocos img {
    width: 640px;
    margin: auto;
    height: auto;
  }
  #blocos .right-blocos .text p {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #5b3e32;
  }
  #blocos .right-blocos .text h3 {
    font-size: 36px;
  }
  #blocos .right-blocos .text a.link {
    font-size: 20px;
  }
  #blocos .text-purple {
    width: 100%;
    padding: 30px;
    text-align: center;
  }
  #blocos .text-purple p {
    color: #775ca7;
  }
  #blocos ul.links {
    width: 250px;
    padding: 50px 0 0;
    margin: auto;
  }
  #blocos ul.links li {
    list-style: none;
    margin: 5px 0;
  }
  #blocos ul.links li a {
    padding: 0 0 0 20px;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 20px;
    color: #c8b59e;
    position: relative;
  }
  #blocos ul.links li a:after {
    width: 13px;
    height: 1px;
    background: #c8b59e;
    content: '';
    bottom: 15px;
    left: 0;
    position: absolute;
  }
  #mais-vendidos .mais-vendidos-menu {
    width: 100%;
  }
  #mais-vendidos .mais-vendidos-menu li a {
    font-size: 20px;
  }

  .bg-danger {
    text-align: center;
    border: #ae1f6f 2px solid;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 20px;
    color: #ae1f6f;
  }
}

@media (max-width: 768px) {
  body {
    padding: 70px 0;
  }
  .related-products .wrapper-price small,
  .product-list.related-products .wrapper-price small {
    font-size: 12px !important;
  }
  .related-products .discount-price,
  .product-list.related-products .discount-price {
    top: -6px !important;
  }
  .related-products .final-price,
  .product-list.related-products .final-price {
    padding-top: 7px !important;
  }
  .banner-blackfriday .img {
    height: auto !important;
    background-image: none;
  }
  .banner-blackfriday img {
    width: 100%;
    height: auto;
  }
}
